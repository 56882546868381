<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">
                {{ $t("CASH_REGISTER_READINGS.ADD_CASH_REGISTER_READING") }}
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <cash-register-reading-form
              :loading="loading"
              :cashRegisterReadingData="cashRegisterReading"
              :formErrors="formErrors"
              @cashRegisterReadingSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultCashRegisterReading from "./defaultCashRegisterReading";
import CashRegisterReadingForm from "./partials/CashRegisterReadingForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    CashRegisterReadingForm,
  },

  mixins: [alertLeave],

  data() {
    const cashRegisterReading = cloneDeep(defaultCashRegisterReading);
    return {
      cashRegisterReading: cashRegisterReading,
      formErrors: null,
      loading: false,
    };
  },

  methods: {
    goBack() {
      this.$router.push({ name: "List CashRegisterReadings" });
    },

    viewCashRegisterReading(cashRegisterReading) {
      this.alertLeave = false;
      this.$router.push({
        name: "View CashRegisterReading",
        params: { id: cashRegisterReading.id },
      });
    },

    async handleSubmit(cashRegisterReading) {
      this.loading = true;
      const cashRegisterReadingData = cloneDeep(cashRegisterReading);
      if (cashRegisterReadingData.serviceCenter) {
        if (!cashRegisterReadingData.serviceCenter.id) {
          delete cashRegisterReadingData.serviceCenter;
        }
      }
      if (cashRegisterReadingData.establishment) {
        if (!cashRegisterReadingData.establishment.id) {
          delete cashRegisterReadingData.establishment;
        }
      }
      if (cashRegisterReadingData.cafeteria) {
        if (!cashRegisterReadingData.cafeteria.id) {
          delete cashRegisterReadingData.cafeteria;
        }
      }

      try {
        await this.$store.dispatch(
          "cashRegisterReadings/add",
          cashRegisterReadingData
        );
        this.$notify({
          type: "success",
          message: this.$t(
            "CASH_REGISTER_READINGS.CASH_REGISTER_READING_ADDED"
          ),
        });
        const cashRegisterReading = await this.$store.getters[
          "cashRegisterReadings/cashRegisterReading"
        ];
        this.viewCashRegisterReading(cashRegisterReading);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        const cashRegisterReading = await this.$store.getters[
          "cashRegisterReadings/cashRegisterReading"
        ];
        if (cashRegisterReading.id) {
          await this.$store.dispatch(
            "cashRegisterReadings/destroy",
            cashRegister.id
          );
        }
        this.loading = false;
      }
    },
  },
};
</script>
